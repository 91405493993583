import React from 'react';

const ContactUs = props => {


  return (
    <section className="contact-us">
      <div className="contact-us-message">
        <h1 className="contact-us-title">Contact FIND</h1> 
        <p className="contact-us-subtitle"><span className="contact-us-subtitle_primary">Address</span><br />The Whitehouse<br />Belvedere Road<br />London<br />SE1 8GA</p>
        <p className="contact-us-subtitle"><span className="contact-us-subtitle_primary">Telephone</span><br />0203 0027449</p>
        <p className="contact-us-subtitle"><span className="contact-us-subtitle_primary">Email</span><br /><a href='mailto:support@findmaps.co.uk' className="contact-us-email">support@findmaps.co.uk</a></p>
      </div>
    </section>
  )
};

export default ContactUs;