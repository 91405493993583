import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import FindCover from "../components/HomeCover";
import Products from "../components/Products";
import DataPortal from "../components/DataMapPortal";
import DataProviders from "../components/DataProviders";
import ContactUs from "../components/ContactUs";

const IndexPage = () => (
  <Layout>
    <SEO title="FIND Mapping Limited" />
    <FindCover />
    <DataPortal />
    <Products />
    <ContactUs />
    <DataProviders />
  </Layout>
);

export default IndexPage;
