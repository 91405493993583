import React from 'react';

const DataMapPortal = props => {

    const openPortal = () => {
        //console.log("openPortal clicked");
        window.open('https://maps.findmaps.co.uk/', "_blank");
    };

  return (
    <section className="datamap-portal">
      <div className="datamap-portal-message">
        <h1 className="datamap-portal-title">FIND data portal</h1> 
        <p className="datamap-portal-subtitle">FIND data portal is now available for all of your data and printing requirements, to access the this portal you will need a FIND username and password. Click 'Get Started' to begin.</p>
        <div className="datamap-portal-button-container"><button className="button datamap-portal-button" onClick={openPortal} >Get Started</button></div>
      </div>
      <div className="datamap-portal-photo-icons">
        <a href='https://maps.findmaps.co.uk/' target='_blank' rel="noreferrer" aria-label="Goto mapping portal" ><div className="datamap-portal-photo1"></div></a>
        <a href='https://maps.findmaps.co.uk/' target='_blank' rel="noreferrer" aria-label="Goto mapping portal"><div className="datamap-portal-photo2"></div></a>
        <a href='https://maps.findmaps.co.uk/' target='_blank' rel="noreferrer" aria-label="Goto mapping portal"><div className="datamap-portal-photo3"></div></a>
      </div>
    </section>
  )
};

export default DataMapPortal;