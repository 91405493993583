import React from 'react';

const FindProp = props => {

  return (
    <section className="homecover-prop">
        <div className="homecover-photo"></div>
    </section>
  )
};

export default FindProp;