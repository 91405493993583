import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Button from "./Button";

const Products = props => {

    const openPortal = () => {


    };

  return (
    <section className="product">
      <div className="product-title-container">
        <h1 className="product-title">Our Products and Services</h1> 
        <p className="product-subtitle">Here at FIND, we do a lot and we can offer many products and services. Some of them are listed below, but if you don't see what you are after, give our support team a call.</p>
      </div>
      <div className="product-list-container">
        <Tabs>
        <TabList className="product-tab">
            <Tab>FIND Data Portal</Tab>
            <Tab>Marine</Tab>
            <Tab>Utility Reports</Tab>
            <Tab>Environmental Reports</Tab>
            <Tab>Consultancy</Tab>
            <Tab>Bespoke mapping</Tab>
        </TabList>

        <TabPanel>
            <div className="product-list-details">
              <div className="product-list-heading">
              <h2>New FIND's online mapping portal</h2>
              </div>
                <div className="product-portal-image"></div>
                <div className="product-list-copy">
                  <p className="product-list-first">maps.findmaps.co.uk is a mapping and data portal designed for use by industry professionals.  The simple, user-friendly system provides mapping and data from the UK’s most reputable sources including Ordnance Survey, British Geological Society and the Environment Agency.  The site offers a huge range of data layers to view, print and download.</p>
                  <p className="product-list-first">Register now to access <a href='https://maps.findmaps.co.uk/' target='_blank' rel="noreferrer" aria-label="Goto mapping portal" >maps.findmaps.co.uk</a></p>
                </div> 
            </div>
        </TabPanel>
        <TabPanel>
          <div className="product-list-details">
            <div className="product-list-heading">
              <h2>Marine maps and data</h2>
            </div>
            <div className="product-marine-image"></div>
            <div className="product-list-copy">
              <p className="product-list-first">MarineFIND is the specialist marine data and service division of FIND Maps, an established provider of land based mapping and geoinformation services to a diverse range of clients. By partnering with leading marine data suppliers and software providers, MarineFIND is able to provide our customers with cost effective data solutions.</p>
              <p className="product-list-first">To learn more about our marine data and services contact us on marinesupport@findmaps.co.uk or call us on 0203 0027449.</p>
            </div>   
          </div>
        </TabPanel>
        <TabPanel>
        <div className="product-list-details">
            <div className="product-list-heading">
              <h2>Underground utilities report</h2>
            </div>
            <div className="product-utilities-image"></div>
            <div className="product-list-copy">
              <p className="product-list-first">Our utility report service is a one-stop-shop providing comprehensive coverage of utilities and services.  The reports are presented in a format ideal for use in due diligence, feasibility studies, 'before you dig' checks, consultations and developments.</p>
              <p className="product-list-first">To order a report or learn more contact support@findmaps.co.uk or call 0203 0027449.</p>
            </div>   
          </div>
        </TabPanel>
        <TabPanel>
          <div className="product-list-details">
            <div className="product-list-heading">
              <h2>Groundsure insight bundles</h2>
            </div>
            <div className="product-environment-image"></div>
            <div className="product-list-copy">
              <p className="product-list-first">The GroundSure Insight Bundle comprises all GroundSure Insight reports: MapInsight, EnviroInsight, GeoInsight and FloodInsight.  While we offer these reports separately, this bundle is the most cost-effective way to get them all.</p>
              <p className="product-list-first">The popularity of GroundSure Insight reports rests upon their quality, accuracy and integrity.  They include historical mapping and targeted environmental and geological data.  The reports are easy to interpret and provide a solid basis for efficient site analysis.</p>
              <p className="product-list-first">To order a report or learn more contact support@findmaps.co.uk or call 0203 0027449.</p>
            </div>   
          </div>
        </TabPanel>
        <TabPanel>
          <div className="product-list-details">
            <div className="product-list-heading">
              <h2>Consultancy</h2>
            </div>
            <div className="product-consultancy-image"></div>
            <div className="product-list-copy">
              <p className="product-list-first">From developing custom mapping solutions to bespoke site analysis, the FIND consultancy team is ready to take on any challenge.</p>
              <p className="product-list-first">Satisfied clients include GIA, Experian, London Chamber of Commerce, GL Hearn, London Property Alliance and many more.</p>
              <p className="product-list-first">To explore how we can help contact support@findmaps.co.uk or call 0203 0027449.</p>
            </div>   
          </div>
        </TabPanel>
        <TabPanel>
          <div className="product-list-details">
            <div className="product-list-heading">
              <h2>Bespoke mapping</h2>
            </div>
            <div className="product-bespoke-image"></div>
            <div className="product-list-copy">
              <p className="product-list-first">Sometimes you simply need a location map for a planning application or a wall map.  Our team is happy to help.</p>
              <p className="product-list-first">To order a map or learn more contact support@findmaps.co.uk or call 0203 0027449.</p>
            </div>   
          </div>
        </TabPanel>
        </Tabs>
      </div>
    </section>
  )
};

export default Products;
