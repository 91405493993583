import React from 'react';

const DataProviders = props => {

  return (
    <section className="data-providers">
      <div className="data-providers-message">
        <h1 className="data-providers-title">Our Data Providers</h1> 
        <p className="data-providers-subtitle">We work closely with a number of data suppliers</p>
      </div>
      <div className="data-providers-icons">
        <a href='https://www.ordnancesurvey.co.uk/' target='_blank' rel="noreferrer" aria-label="Ordnance Survey logo"><div className="os"></div></a>
        <a href='https://www.bgs.ac.uk/' target='_blank' rel="noreferrer" aria-label="BGS logo"><div className="bgs"></div></a>
        <a href='https://www.oceanwise.eu/' target='_blank' rel="noreferrer" aria-label="Oceanwise logo"><div className="oceanwise"></div></a>
        <a href='https://www.gov.uk/government/organisations/environment-agency/' target='_blank' rel="noreferrer" aria-label="Environment Agency logo"><div className="ea"></div></a>
        <a href='https://www.groundsure.com/' target='_blank' rel="noreferrer" aria-label="Groundsure logo"><div className="groundsure"></div></a>
        <a href='https://www.getmapping.com/' target='_blank' rel="noreferrer" aria-label="Getmapping logo"><div className="getmapping"></div></a>
      </div>
    </section>
  )
};

export default DataProviders;